import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductCard from './ProductCard';
import { Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  productCatalogContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    gap: '20px',
  },
  productCard: {
    flex: '1 1 30%',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ProductCatalogs = () => {
  const classes = useStyles(); 
  const [productCatalogs, setProductCatalogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(0); 

  useEffect(() => {
    fetchProductCatalogs();
  }, [currentPage]);

  const fetchProductCatalogs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`http://api.scrapdulo.com/shop/product/catalogs`, {
        params: {
          offset: (currentPage - 1),
          limit: limit, 
        },
      });
      const { data } = response.data;
      setProductCatalogs(data);
      setLimit(response.data.limit);
      setTotalPages(Math.ceil(response.data.totalCount / limit));
    } catch (error) {
      console.error('Error fetching product catalogs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className={classes.productCatalogContainer}>
          {productCatalogs.map((product) => (
            <div key={product.id} className={classes.productCard}>
              <ProductCard product={product} />
            </div>
          ))}
        </div>
      )}
      <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
    </div>
  );
};

export default ProductCatalogs;
