import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '24px',
  },
  sectionContent: {
    marginBottom: theme.spacing(2),
    color: '#333333',
    lineHeight: '1.6',
  },
  link: {
    color: '#007bff', // Blue link color
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

function AccountDeletion() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.sectionTitle}>
        Account Deletion
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We understand that you may want to delete your Scrapdulo account and associated data. You can request account deletion by clicking the following link:
      </Typography>

      <a
        href="mailto:support@scrapdulo.com?subject=Account Deletion Request"
        className={classes.link}
      >
        Request Account Deletion
      </a>

      <Typography variant="body1" className={classes.sectionContent}>
        If you have any questions or concerns regarding the deletion of your account and data, please contact us at{' '}
        <a href="mailto:support@scrapdulo.com" className={classes.link}>
          support@scrapdulo.com
        </a>.
      </Typography>
    </div>
  );
}

export default AccountDeletion;
