import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  question: {
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  answer: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    display: 'none',
  },
  answerOpen: {
    display: 'block',
  },
}));

const HelpCenterPage = () => {
  const classes = useStyles();
  const [openIndex, setOpenIndex] = useState(null);
  const handleQuestionClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Help Center
      </Typography>
      <Typography variant="body1" className={classes.content}>
        Need assistance? Our Help Center is here to support you. Whether you have questions about
        using Scrapdulo, need help with your account, or want to understand our services better,
        you can find answers to commonly asked questions below. If you can't find what you're
        looking for, our support team is ready to provide personalized assistance.
      </Typography>
      <div className={classes.section}>
  <Typography variant="h5" className={classes.sectionTitle}>
    Frequently Asked Questions (FAQs)
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(0)}>
    Q: How can I sign up for Scrapdulo?
  </Typography>
  <Typography variant="body1" className={`${classes.answer} ${openIndex === 0 ? classes.answerOpen : ''}`}>
    A: Signing up for Scrapdulo is quick and easy. Simply visit our website or download our
    mobile app from the Google Play Store.
    Follow the prompts to create your account.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(1)}>
    Q: How can I sell my scrap materials?
  </Typography>
  <Typography variant="body1" className={`${classes.answer} ${openIndex === 1 ? classes.answerOpen : ''}`}>
    A: To sell your scrap materials, log in to your Scrapdulo account and navigate to the
    "Sell Scrap" section. Follow the instructions to provide details about your scrap items
    and schedule a pickup. Your Booking will be created and assigned automatically to Our registered scrap dealers.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(2)}>
    Q: How does Scrapdulo assign the scrap pickup request to dealers?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 2 ? classes.answerOpen : ''}`}>
    A: Scrapdulo uses an intelligent algorithm to automatically assign scrap pickup requests
    to nearby scrap dealers who specialize in the specific category of scrap. This ensures
    efficient and convenient transactions for both customers and dealers.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(3)}>
    Q: Are the scrap dealers registered on Scrapdulo authentic?
  </Typography>
  <Typography variant="body1" className={`${classes.answer} ${openIndex === 3 ? classes.answerOpen : ''}`}>
    A: Yes, all scrap dealers registered on Scrapdulo go through a verification process to
    ensure their authenticity. We take the quality and reliability of our dealer network
    seriously to provide the best experience for our customers.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(4)}>
    Q: What types of scrap materials can I sell on Scrapdulo?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 4 ? classes.answerOpen : ''}`}>
    A: Scrapdulo accepts a wide range of scrap materials, including plastics, newspapers,
    metals like copper and brass, electronic waste, and more. Please refer to our website
    or app for a comprehensive list of accepted materials.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(5)}>
    Q: How do I schedule a scrap pickup?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 5 ? classes.answerOpen : ''}`}>
    A: After providing details about your scrap materials, you can select a preferred date
    and time for the pickup during the booking process. Our system will match your availability
    with the schedules of nearby scrap dealers to ensure a convenient pickup experience for you.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(6)}>
    Q: Can I track the status of my scrap pickup?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 6 ? classes.answerOpen : ''}`}>
    A: Yes, you can track the status of your scrap pickup through your Scrapdulo account. Once
    a dealer is assigned and the pickup is scheduled, you will receive updates regarding the
    pickup status and any changes in the timeline.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(7)}>
    Q: How do I get paid for my scrap materials?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 7 ? classes.answerOpen : ''}`}>
    A: Payment for your scrap materials is typically made by the assigned scrap dealer upon
    pickup. They will provide you with the agreed-upon amount based on the value of the scrap
    materials. Payment options may vary depending on the dealer and your location.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(8)}>
    Q: What if I have a complaint or issue with a scrap dealer?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 8 ? classes.answerOpen : ''}`}>
    A: If you encounter any issues or have a complaint regarding a scrap dealer, please
    contact our support team immediately. We will investigate the matter and take appropriate
    actions to ensure a satisfactory resolution.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(9)}>
    Q: Is Scrapdulo available in multiple cities?
  </Typography>
  <Typography variant="body1" className={`${classes.answer} ${openIndex === 9 ? classes.answerOpen : ''}`}>
    A: Yes, Scrapdulo is expanding its services to multiple cities. Currently, we operate in
    Delhi, India. We are continuously working to add more
    cities to our network to serve a wider customer base.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(10)}>
    Q: Can I cancel a scrap pickup request?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 10 ? classes.answerOpen : ''}`}>
    A: Yes, you can cancel a scrap pickup request by contacting our support team. However, it
    is recommended to cancel as early as possible to avoid any inconvenience for the assigned
    scrap dealer and to maintain a smooth process for all parties involved.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(11)}>
    Q: How can I change my account settings or update my personal information?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 11 ? classes.answerOpen : ''}`}>
    A: You can easily update your account settings and personal information by logging in to
    your Scrapdulo account and navigating to the "Profile" or "Settings" section. From there,
    you can make the necessary changes and save the updated information.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(12)}>
    Q: Are there any fees or charges for using Scrapdulo?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 12 ? classes.answerOpen : ''}`}>
    A: Scrapdulo does not charge any fees to customers for using the platform. However, please
    note that there may be fees or charges associated with specific services or transactions
    conducted through our registered scrap dealers. The details of any applicable fees will be
    communicated to you during the booking process.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(13)}>
    Q: Can I leave a review or rating for a scrap dealer?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 13 ? classes.answerOpen : ''}`}>
    A: Yes, after completing a scrap pickup and transaction, you will have the option to leave
    a review and rating for the assigned scrap dealer. Your feedback is valuable in maintaining
    the quality and reliability of our dealer network.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(14)}>
    Q: Is Scrapdulo available for commercial or industrial scrap materials?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 14 ? classes.answerOpen : ''}`}>
    A: Yes, Scrapdulo caters to both residential and commercial customers. If you have
    commercial or industrial scrap materials to sell, please provide the necessary details
    during the booking process, and our system will match you with appropriate scrap dealers
    who specialize in handling such materials.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(15)}>
    Q: Can I schedule recurring scrap pickups?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 15 ? classes.answerOpen : ''}`}>
    A: Currently, Scrapdulo supports one-time scrap pickups. If you have recurring scrap
    disposal needs, please contact our support team to discuss possible arrangements or
    alternative solutions.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(16)}>
    Q: Is my personal information secure on Scrapdulo?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 16 ? classes.answerOpen : ''}`}>
    A: Yes, Scrapdulo takes the security and privacy of your personal information seriously.
    We have implemented industry-standard measures to protect your data and ensure secure
    transactions. For more information, please refer to our Privacy Policy on our website.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(17)}>
    Q: What should I do if I forget my password?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 17 ? classes.answerOpen : ''}`}>
    A: If you forget your password, you can use the "Forgot Password" feature on the login
    page. Follow the instructions to reset your password. If you encounter any issues, please
    contact our support team for further assistance.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(18)}>
    Q: Can I use Scrapdulo to donate my scrap materials?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 18 ? classes.answerOpen : ''}`}>
    A: At the moment, Scrapdulo focuses on facilitating transactions between customers and
    scrap dealers. However, we appreciate your interest in donating scrap materials. We
    encourage you to explore local charitable organizations or recycling initiatives that
    accept donations of scrap materials.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(19)}>
    Q: How can I provide feedback or suggestions to Scrapdulo?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 19 ? classes.answerOpen : ''}`}>
    A: We value your feedback and suggestions. You can provide feedback by contacting our
    support team or using the feedback form available on our website. We appreciate your
    input in helping us improve our services.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(20)}>
    Q: Is Scrapdulo available 24/7?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 20 ? classes.answerOpen : ''}`}>
    A: Yes, Scrapdulo's online platform is accessible 24/7 for browsing, account management,
    and general inquiries. However, please note that the availability of scrap pickups and
    dealer interactions may vary based on the operating hours of our registered scrap dealers.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(21)}>
    Q: How long does it take for a scrap dealer to respond to my booking request?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 21 ? classes.answerOpen : ''}`}>
    A: The response time from scrap dealers may vary depending on their availability and the
    volume of requests they receive. Typically, dealers strive to respond within 24-48 hours
    to discuss further details and schedule the pickup.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(22)}>
    Q: Can I request a specific scrap dealer for my pickup?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 22 ? classes.answerOpen : ''}`}>
    A: Scrapdulo's system automatically assigns the nearest available scrap dealer who deals
    in the specific category of your scrap materials. While you cannot request a specific
    dealer, rest assured that all our registered dealers are verified and trustworthy.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(23)}>
    Q: What types of scrap materials can I sell through Scrapdulo?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 23 ? classes.answerOpen : ''}`}>
    A: Scrapdulo accepts various types of scrap materials, including plastics, newspapers,
    metals such as copper, brass, aluminum, steel, and more. You can provide specific details
    about your materials during the booking process.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(24)}>
    Q: Can I sell damaged or broken scrap materials?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 24 ? classes.answerOpen : ''}`}>
    A: Yes, you can sell damaged or broken scrap materials as long as they fall within the
    accepted categories. However, the value of the materials may be determined based on their
    condition and market demand.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(26)}>
    Q: Do I need to separate different types of scrap materials for pickup?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 26 ? classes.answerOpen : ''}`}>
    A: It is helpful to separate different types of scrap materials, such as plastics, metals,
    and paper, as it streamlines the pickup process. However, if you are unable to separate
    them, you can discuss it with the assigned scrap dealer during the communication process.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(27)}>
    Q: Can I sell scrap materials from multiple categories in a single pickup?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 27 ? classes.answerOpen : ''}`}>
    A: Yes, you can sell scrap materials from multiple categories in a single pickup. During
    the booking process, provide details about the different types of materials you wish to
    sell, and the assigned scrap dealer will coordinate accordingly.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(28)}>
    Q: Is there a minimum quantity of scrap materials required for pickup?
  </Typography>
  <Typography variant="body1" className={`${classes.answer} ${openIndex === 28 ? classes.answerOpen : ''}`}>
    A: The minimum quantity requirement may vary depending on the scrap category and the
    policies of the assigned scrap dealer. However, Scrapdulo aims to accommodate customers
    with varying quantities. You can proceed with the booking process, and the dealer will
    inform you if any minimum quantity conditions apply.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(29)}>
    Q: How do I know the current market value of my scrap materials?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 29 ? classes.answerOpen : ''}`}>
    A: Scrapdulo provides estimated market values for different types of scrap materials based
    on market trends. However, please note that the final value may vary depending on factors
    such as quantity, quality, and market fluctuations. The assigned scrap dealer will provide
    a more accurate valuation during the pickup process.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(30)}>
    Q: Can I negotiate the price of my scrap materials with the dealer?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 30 ? classes.answerOpen : ''}`}>
    A: Yes, you can negotiate the price of your scrap materials with the dealer. While Scrapdulo
    provides estimated values, the final price is subject to negotiation based on factors such
    as market conditions, quantity, and quality. It is recommended to discuss the price during
    the communication with the assigned dealer.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(31)}>
    Q: How do I schedule a pickup for my scrap materials?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 31 ? classes.answerOpen : ''}`}>
    A: To schedule a pickup, log in to your Scrapdulo account, go to the "Sell Scrap" section,
    and follow the instructions to provide details about your materials. The assigned scrap
    dealer will contact you to finalize the pickup schedule.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(32)}>
    Q: Can I track the status of my scrap pickup?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 32 ? classes.answerOpen : ''}`}>
    A: Yes, you can track the status of your scrap pickup through your Scrapdulo account. Once
    the pickup is scheduled, you will receive updates on the progress, including confirmation
    from the assigned dealer, estimated pickup time, and any changes in the schedule if necessary.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(33)}>
    Q: What should I do if there is a delay or issue with my scrap pickup?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 33 ? classes.answerOpen : ''}`}>
    A: If you experience a delay or encounter any issues with your scrap pickup, please contact
    our support team immediately. We will investigate the matter and work to resolve it promptly
    to ensure a smooth transaction.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(35)}>
    Q: What payment methods are available for scrap transactions?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 35 ? classes.answerOpen : ''}`}>
    A: The available payment methods may vary depending on the scrap dealer. Common payment
    methods include cash, bank transfer, or electronic payment apps. The accepted payment options
    will be discussed between you and the assigned dealer during the pickup process.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(36)}>
    Q: Can I request an invoice or receipt for my scrap transaction?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 36 ? classes.answerOpen : ''}`}>
    A: Yes, you can request an invoice or receipt for your scrap transaction. Please communicate
    your request with the assigned scrap dealer, and they will provide the necessary documentation
    to support your records.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(37)}>
    Q: Are there any fees or charges associated with using Scrapdulo's services?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 37 ? classes.answerOpen : ''}`}>
    A: There are no fees or charges for using Scrapdulo's services as a customer. However, please
    note that the scrap dealer may apply certain fees or deductions based on factors such as
    transportation costs, processing fees, or quality assessment. These details will be discussed
    between you and the dealer during the pickup process.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(38)}>
    Q: What if I have a complaint or issue with the scrap dealer?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 38 ? classes.answerOpen : ''}`}>
    A: If you have any complaints or issues with the scrap dealer, please contact our support
    team immediately. We take customer feedback seriously and will investigate the matter to
    ensure a satisfactory resolution.
  </Typography>
  <Typography variant="body1" className={classes.question} onClick={() => handleQuestionClick(39)}>
    Q: How can I provide feedback or suggestions for Scrapdulo's services?
  </Typography>
  <Typography variant="body1"  className={`${classes.answer} ${openIndex === 39 ? classes.answerOpen : ''}`}>
    A: We welcome your feedback and suggestions to improve Scrapdulo's services. You can send
    your feedback to our support team via email or contact us through the provided contact
    information. We appreciate your input and strive to enhance your experience.
  </Typography>
  {/* Add more FAQs and answers here */}
</div>

      <div className={classes.section}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Contact Our Support Team
        </Typography>
        <Typography variant="body1" className={classes.content}>
          If you can't find the information you're looking for in our FAQs or need personalized
          assistance, our support team is here to help. You can reach out to us via the following
          channels:
        </Typography>
        <ul className={classes.content}>
          <li>Email: support@scrapdulo.com</li>
          <li>Phone: +91-844-828-0993</li>
        </ul>
        <Typography variant="body1" className={classes.content}>
          Our support team is available during business hours and strives to respond to inquiries
          promptly. We are dedicated to providing excellent customer service and ensuring your
          experience with Scrapdulo is smooth and hassle-free.
        </Typography>
      </div>
    </div>
  );
};

export default HelpCenterPage;
