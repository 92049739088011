import React, { useState } from 'react';
import { Typography, Button, TextField, Grid, Select, MenuItem, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import backgroundImage from '../assets/background.jpg';
import logo from '../assets/scrapdulo_logo_large.jpg';
import sellYourScrap from '../assets/sell_your_scrap.jpg';
import SeamlessBookingProcess from '../assets/SeamlessBookingProcess.jpg';
import qrCode from '../assets/qr_code_with_brand.png'; // Import QR code image
import BookingSteps from './BookingSteps';
import WhyUsSection from './WhyUs';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',
    padding: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8))',
      zIndex: -1,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: theme.spacing(3),
    borderRadius: '10px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-10px',
      left: '-10px',
      right: '-10px',
      bottom: '-10px',
      borderRadius: '20px',
      border: '2px solid rgba(255, 255, 255, 0.2)',
      pointerEvents: 'none',
    },
  },
  button: {
    marginTop: theme.spacing(3),
  },
  qrCodeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  qrCode: {
    width: '200px', // Set the size of the QR code image
    height: '200px',
  },
  formContainer: {
    marginTop: theme.spacing(4),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  componentContainer: {
    marginTop: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(4),
  },
  component: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: '#ffffff',
    padding: theme.spacing(3),
    borderRadius: '10px',
  },
  componentImage: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const [successMessage, setSuccessMessage] = useState('');
  
  const handleDownloadApp = () => {
    // Redirect to ScrapDULO app download page on Google Play
    window.open('https://play.google.com/store/apps/details?id=com.scrapdulo.android', '_blank');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccessMessage('We have received your information. Our nearby dealer will contact you soon.');
    setShowForm(false);
    setFormData({
      mobileNumber: '',
      scrapType: '',
      quantity: '',
      address: '',
      email: '',
      dateTime: null,
    });
  };

  const handleNewRequest = () => {
    setSuccessMessage('');
    setShowForm(true);
  };

  const [showForm, setShowForm] = useState(false);
  const handleGetStarted = () => {
    setShowForm(true);
  };

  const [formData, setFormData] = useState({
    mobileNumber: '',
    scrapType: '',
    quantity: '',
    address: '',
    email: '',
    dateTime: null,
  });

  const [scrapTypes, setScrapTypes] = useState([
    'Newspaper',
    'Cardboard',
    'Copper',
    'Metal',
    'Aluminium',
    'Plastic',
    'Household Items',
    'Mixed Scrap',
    'Others',
  ]);

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    const isValidInput = /^[0-9]*\.?[0-9]*$/.test(value);
    if (isValidInput || value === '') {
      setFormData({ ...formData, quantity: value });
    }
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    const cleanedValue = value.replace(/\D/g, '');
    const isValidMobileNumber = cleanedValue.length === 10 && cleanedValue.startsWith('91');
    const formattedValue = isValidMobileNumber ? `+91-${cleanedValue}` : cleanedValue;
    setFormData({ ...formData, mobileNumber: formattedValue });
  };

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.textContainer}>
          {successMessage ? (
            <Typography variant="h6" align="center" gutterBottom>
              {successMessage}
            </Typography>
          ) : (
            <>
              <Typography variant="h6" align="center" gutterBottom>
                Sell your scrap hassle-free to authentic dealers near you
              </Typography>
              
                {/* Hide the Get Started button */}
              {/* {!showForm && (
                
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  onClick={handleGetStarted}
                >
                  Get Started
                </Button>
              )} */}
            </>
          )}
          {showForm && (
            <div className={classes.formContainer}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  value={formData.mobileNumber}
                  onChange={handleMobileNumberChange}
                  required
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    title: 'Please enter a valid mobile number',
                  }}
                />
                <Select
                  labelId="scrap-type-label"
                  label="Scrap Type"
                  variant="outlined"
                  value={formData.scrapType}
                  onChange={(e) => setFormData({ ...formData, scrapType: e.target.value })}
                  required
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    Select Scrap Type
                  </MenuItem>
                  {scrapTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  label="Quantity (KG)"
                  variant="outlined"
                  value={formData.quantity}
                  onChange={handleQuantityChange}
                  required
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*\\.?[0-9]*',
                    title: 'Please enter a numeric value in KG',
                  }}
                />
                <TextField
                  label="Address"
                  variant="outlined"
                  value={formData.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                  required
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submitButton}
                >
                  Submit Pickup Request
                </Button>
              </form>
            </div>
          )}
          {successMessage && (
            <div className={classes.formContainer}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.submitButton}
                onClick={handleNewRequest}
              >
                Create New Request
              </Button>
            </div>
          )}
          <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              onClick={handleDownloadApp}
            >
              Download App
            </Button>
          {/* Download App Button and QR code */}
          <div className={classes.qrCodeContainer}>
            
            <img src={qrCode} alt="QR Code for Downloading App" className={classes.qrCode} 
              onClick={handleDownloadApp} />
          </div>
        </div>
      </div>
      <div className={classes.componentContainer}>
        <div className={classes.component}>
          <img src={sellYourScrap} alt="Sell Your Scrap" className={classes.componentImage} />
          <Typography variant="h5">Sell Your Scrap</Typography>
          <Typography variant='body2'>
            Upload the details of your scrap and get the best prices from nearby dealers.
          </Typography>
        </div>
        <div className={classes.component}>
          <img src={SeamlessBookingProcess} alt="Seamless Booking Process" className={classes.componentImage} />
          <Typography variant="h5">Seamless Booking Process</Typography>
          <Typography variant='body2'>
            Book a pickup request in a few easy steps with real-time dealer availability.
          </Typography>
        </div>
      </div>
      <BookingSteps />
      <WhyUsSection />
    </div>
  );
};

export default HomePage;
