// import React from 'react';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   copyright: {
//     backgroundColor: '#333',
//     color: '#fff',
//     textAlign: 'center',
//     padding: '10px',
//   },
// }));

// const Footer = () => {
//   const classes = useStyles();

//   return (
//     <div className={classes.copyright}>
//       <p>&copy; {new Date().getFullYear()} Scrapdulo. All rights reserved.</p>
//     </div>
//   );
// };

// export default Footer;


import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Grid, Typography, Link } from '@mui/material';
import logo from '../assets/scrapdulo_logo_large.jpg';

const useStyles = makeStyles((theme) => ({
  footer: {
    // backgroundColor: theme.palette.secondary.main,
    
    backgroundColor: "black",

    padding: theme.spacing(4),
    color: '#ffffff',
  },
  logo: {
    width: '80px',
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'block',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  copyright: {
    color: '#fff',
    textAlign: 'center'
  }
}));

const Footer = () => {
  const classes = useStyles();
  const handleAndroidDownload = (e) => {
    // Redirect to ScrapDULO app download page on Google Play
    window.open('https://play.google.com/store/apps/details?id=com.scrapdulo.android', '_blank');
  };
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              ScrapDULO
            </Typography>
            <img src={logo} alt="Scrapdulo Main Logo" className={classes.logo} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" gutterBottom>
              Download
            </Typography>
            <Link href="#" className={classes.link}  onClick={handleAndroidDownload}>
              Android
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3} hidden>
            <Typography variant="subtitle1" gutterBottom>
              What we do
            </Typography>
            <Link href="#" className={classes.link}>
              Features
            </Link>
            <Link href="#" className={classes.link}>
              Blog
            </Link>
            <Link href="#" className={classes.link}>
              Stories
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" gutterBottom>
              For Business
            </Typography>
            <Link href="/contact" className={classes.link}>
              Contact Us
            </Link>
            {/* <Link href="#"  className={classes.link} >
              Who we are
            </Link> */}
            <Link href="/about" className={classes.link}>
              About us
            </Link>
            {/* <Link href="#" className={classes.link} hidden>
              Careers
            </Link>
            <Link href="#" className={classes.link} hidden>
              Brand Center
            </Link> */}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" gutterBottom>
              Privacy
            </Typography>
            <Link href="/privacy-policy" className={classes.link}>
              Use ScrapDULO
            </Link>
            <Link href="/privacy-policy" className={classes.link}>
              Privacy Policy
            </Link>
            <Link href="/terms-and-conditions" className={classes.link}>
             Terms and Conditions
            </Link>
            <Link href="/account-deletion" className={classes.link}>
             Account and Data Deletion
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" gutterBottom>
              Need help?
            </Typography>
            <Link href="/contact" className={classes.link} hidden>
              Contact Us
            </Link>
            <Link href="/help-center" className={classes.link}>
              Help Center
            </Link>
            {/* <Link href="#" className={classes.link}>
              Coronavirus
            </Link>
            <Link href="#" className={classes.link}>
              Security Advisories
            </Link> */}
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="subtitle1" gutterBottom>
              Credits
            </Typography>
            <Link href="#" className={classes.link}>
            Image by <a href="https://www.freepik.com/free-photo/digital-screen-with-environment-day_12976397.htm#query=scrap&position=21&from_view=search&track=sph">Freepik</a>
            </Link>
            <Link href="#" className={classes.link}>
            <a href="https://www.freepik.com/free-vector/car-dump-cartoon-concept-with-worker-operating-auto-crushing-machine-vector-illustration_39094667.htm#query=scrap%20dealer&position=4&from_view=search&track=ais">Image by macrovector</a> on Freepik
            </Link>
            <Link href="#" className={classes.link}>
            <a href="https://www.freepik.com/free-vector/garbage-disposal-illustration_14683592.htm#query=sell%20scrap&position=48&from_view=search&track=ais">Image by macrovector</a> on Freepik
            </Link>
            <Link href="#" className={classes.link}>
            Image by <a href="https://www.freepik.com/free-vector/landing-page-transfer-files-concept_5614978.htm#query=Scrap%20Booking%20App&position=37&from_view=search&track=ais">Freepik</a>
            </Link>
          </Grid>
        </Grid>
      </Container>
      
    <div className={classes.copyright}>
      <p>&copy; {new Date().getFullYear()} DULO Enterprises. All rights reserved.</p>
    </div>

    </footer>
  );
};

export default Footer;

