import React from 'react';
import { Button } from '@mui/material';

const ProductCard = ({ product }) => {
  const handleDownloadApp = () => {
    window.open('https://play.google.com/store/apps/details?id=com.scrapdulo.android', '_blank');
  };

  if (!product) {
    return null;
  }

  const discountedPrice = 100;
  const discountPercent = 50;

  return (
    <div style={{ 
      border: '1px solid #ccc', 
      borderRadius: '5px', 
      padding: '10px', 
      margin: '10px', 
      width: '250px', 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%', // Ensures cards have the same height
    }}>
      <img 
        src={product.thumbnail} 
        alt={product.name} 
        style={{ width: '100%', borderRadius: '5px 5px 0 0', objectFit: 'cover', height: '200px' }} 
      />
      <div style={{ padding: '15px', flexGrow: 1 }}> {/* flexGrow to expand content */}
        <h3 style={{ margin: '0', fontSize: '18px', fontWeight: 'bold' }}>{product.name}</h3>
        <p 
          style={{ margin: '5px 0', color: '#888', fontSize: '14px', maxHeight: '60px', overflow: 'hidden' }}
        >
          {product.description}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <p style={{ margin: '5px 0', fontSize: '16px', fontWeight: 'bold' }}>Price: ₹{product.price}</p>
            {discountPercent > 0 && (
              <p style={{ margin: '5px 0', fontSize: '14px', color: '#888' }}>Discount: {discountPercent}%</p>
            )}
          </div>
          {discountPercent > 0 && (
            <p style={{ margin: '5px 0', fontSize: '16px', fontWeight: 'bold', color: 'red' }}>Offer Price: ₹{discountedPrice.toFixed(2)}</p>
          )}
        </div>
      </div>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleDownloadApp}
        style={{ marginTop: '10px' }}
      >
        Download App to Call Seller
      </Button>
    </div>
  );
};

export default ProductCard;
