import React from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import HelpCenterPage from './components/HelpCenterPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TnC';
import GetRSA from './components/GetRSA';
import AccountDeletion from './components/AccountDeletion';
import ProductList from './components/ProductList';
import ProductCatalogs from './components/ProductCatalogs';


const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/help-center' element={<HelpCenterPage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/Terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/account-deletion' element={<AccountDeletion />} />
        <Route path='/product-catalogs' element={<ProductCatalogs />} />
        {/* <Route
        path="/getRSA"
        render={({ location }) => {
          const searchParams = new URLSearchParams(location.search);
          const accessCode = searchParams.get('access_code');
          const orderId = searchParams.get('order_id');

          return (
            <GetRSA access_code={accessCode} order_id={orderId} />
          );
        }}
      /> */}
      </Routes>
    </Router>
  );
};

export default App;
