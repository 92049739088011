import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from './App';
import Footer from './components/Footer';
import './index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#008069',
    },
    secondary: {
      main: '#428c7f',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <App />
      <Footer />
    </React.Fragment>
   </ThemeProvider>,
  document.getElementById('root')
);
