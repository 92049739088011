import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import scrapduloLogo from '../assets/scrapdulo_logo_large.jpg';
import customerIcon from '../assets/customer_icon.svg';
import dealerIcon from '../assets/dealer_icon.svg';
import scrapduloOfficeImage from '../assets/animateScrapduloOfficeImage.jpg';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  logo: {
    width: '200px',
    marginBottom: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
    height: '70px'
  },
  image: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  highlightedSection: {
    backgroundColor: '#f7f7f7',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  companyInfo: {
    marginLeft: theme.spacing(3),
  },
}));

const AboutPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title} hidden>
        About Scrapdulo
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <img src={scrapduloLogo} alt="Scrapdulo Logo" className={classes.logo} />
        </Grid>
        <Grid item xs={12} sm={8}>
      <div className={classes.highlightedSection} >
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.title}>
          Welcome to Scrapdulo
        </Typography>
        <Typography variant="h6" gutterBottom className={classes.title}>
        A platform owned by DULO Enterprises
        </Typography>
        <Typography variant="body1">
          Established in May 2023 by Shahrukh Khan, Scrapdulo offers you the convenience of selling your scrap materials with just a few clicks. Together, we can make a positive impact on the environment and create a sustainable future.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h6" gutterBottom>
            Establishment: Dulo Enterprises
          </Typography>
          <Typography variant="h6" gutterBottom>
            Founder: Shahrukh Khan
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="body1" gutterBottom>
            We are proud to announce that DULO Enterprises has been registered under the Delhi Shops & Establishment Act, 1954, on 9th day of May 2023.
          </Typography>
          
          <Typography variant="body1" gutterBottom>
          Registration No. : 2023078466
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Company Address:
        </Typography>
        <Typography variant="body1">
          H No. C70, Gali No 15, Madanpur Khadar Extension
        </Typography>
        <Typography variant="body1">
          Sarita Vihar
        </Typography>
        <Typography variant="body1">
          New Delhi
        </Typography>
        <Typography variant="body1">
          Pin: 110076
        </Typography>
      </Grid>
      </div>
    </Grid>
      </Grid>      <div className={classes.section}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Our Mission
        </Typography>
        <Typography variant="body1" className={classes.content}>
          At Scrapdulo, our mission is to revolutionize the scrap industry by providing a seamless
          and efficient platform for individuals and businesses to sell their scrap materials. We
          aim to promote sustainable practices and contribute to a cleaner environment.
        </Typography>
        <Typography variant="body1" className={classes.content}>
          Whether you're a homeowner looking to declutter your space or a business owner seeking to
          responsibly dispose of industrial waste, Scrapdulo is here to simplify the process and
          connect you with registered scrap dealers in your area.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="h5" className={classes.sectionTitle}>
          How it Works
        </Typography>
        <div className={classes.content}>
          <img src={customerIcon} alt="Customer Icon" className={classes.icon} />
          <Typography variant="body1" component="span">
            Customers sign up on our platform, provide details about the type and quantity of scrap
            they have, and schedule a pickup.
          </Typography>
        </div>
        <div className={classes.content}>
          <img src={dealerIcon} alt="Dealer Icon" className={classes.icon} />
          <Typography variant="body1" component="span">
            Registered scrap dealers receive notifications about new pickup requests and can submit
            offers to purchase the scrap materials.
          </Typography>
        </div>
        <Typography variant="body1" className={classes.content}>
          We ensure a transparent and secure transaction process, and once the offer is accepted, the
          scrap materials are collected from the customer's location and the payment is made
          promptly.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Our Commitment to Quality
        </Typography>
        <Typography variant="body1" className={classes.content}>
          Scrapdulo is dedicated to maintaining high standards of quality and integrity. We carefully
          verify the credentials and licenses of the registered scrap dealers in our network to
          ensure they meet regulatory requirements and provide reliable services.
        </Typography>
        <Typography variant="body1" className={classes.content}>
          Additionally, we encourage users to provide feedback and ratings for their experience with
          scrap dealers, allowing us to continuously improve and maintain a trustworthy ecosystem.
        </Typography>
      </div>
      <img
        src={scrapduloOfficeImage}
        alt="Scrapdulo Office"
        className={classes.image}
      />
      <Box className={classes.highlightedSection}>
      <Typography variant="h2" className={classes.sectionTitle}>
        Join ScrapDULO Today!
      </Typography>

      <Typography variant="body1" className={classes.content}>
        Join ScrapDULO today and start selling your scrap materials hassle-free! As a platform owned by DULO Enterprises,
        we are committed to creating a sustainable future by promoting recycling and responsible waste management.
      </Typography>

      <Typography variant="body1" className={classes.content}>
        Whether you are an individual scrap dealer or a business specializing in scrap collection, we welcome you to become
        a part of our network. By registering with us, you can expand your customer base, streamline your operations, and
        benefit from our user-friendly platform.
      </Typography>

      <Typography variant="body1" className={classes.content}>
        To begin the registration process, please send your registration request to{' '}
        <a href="mailto:business@scrapdulo.com">business@scrapdulo.com</a>. Our dedicated team at DULO Enterprises will
        review your request and get in touch with you to discuss the next steps. We are here to support you throughout the
        onboarding process and ensure a smooth transition onto our platform.
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        Join us today and be a part of the ScrapDULO community, where sustainable practices and profitable opportunities
        go hand in hand. Together, let's make a positive impact on the environment while growing your scrap business.
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We look forward to welcoming you aboard!
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        Best regards,<br />
        DULO Enterprises
      </Typography>
        {/* <Typography variant="body1" className={classes.content}>
          Company Name: DULO Enterprises
        </Typography> */}
        <Typography variant="body1" className={classes.content}>
        Company Address: H No. C70, Gali No 15, Madanpur Khadar Extension,
        Sarita Vihar, New Delhi, Pin: 110076</Typography>
      </Box>
    </div>
  );
};

export default AboutPage;
