import React, { useEffect, useState } from 'react';

const GetRSA = ({ access_code, order_id }) => {
  const [response, setResponse] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://secure.ccavenue.com/transaction/getRSAKey?access_code=${access_code}&order_id=${order_id}`;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        mode: 'cors',
      };

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.text();
        setResponse(data);
      } catch (error) {
        console.log('Bank gateway down.', error);
      }
    };

    fetchData();
  }, [access_code, order_id]);

  return (
    <div>
      <h1>PAYMENT CHEKOUT PAGE</h1>
      {/* Render the response */}
      {response}
      <h1>CHEKOUT</h1>
    </div>
  );
};

export default GetRSA;
