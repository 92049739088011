import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar, Typography } from '@mui/material';
import logo from '../assets/scrapdulo_logo_large.jpg';
import backgroundImage from '../assets/nav_background.jpg';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    // height:'56px',
    // backgroundImage: `url(${backgroundImage})`,
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
    // '&::before': {
    //   content: '""',
    //   position: 'absolute',
    //   bottom: 0,
    //   left: 0,
    //   width: '100%',
    //   height: '100%',
    //   background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, ${theme.palette.background.default} 0%)`,
    //   zIndex: -1,
    // },
    // backdropFilter: 'blur(500px)',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    width: '40px'
  },
  navLink: {
    textDecoration: 'none',
    color: 'black',
    paddingLeft: theme.spacing(1), // Add left padding
    paddingRight: theme.spacing(1), // Add right padding
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();

  return (
    <AppBar style={{background:"white"}} position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <img src={logo} alt="Scrapdulo Logo" className={classes.logo} />
        <div className={classes.toolbar}>
          <Typography fontWeight="bold" variant="h9" component={Link} to="/" className={classes.navLink}>
            Home
          </Typography>
          <Typography fontWeight="bold" variant="h7" component={Link} to="/product-catalogs" className={classes.navLink}>
            Shopping
          </Typography>
          <Typography fontWeight="bold" variant="h7" component={Link} to="/about" className={classes.navLink}>
            About
          </Typography>
          <Typography fontWeight="bold" variant="h7" component={Link} to="/contact" className={classes.navLink}>
            Contact
          </Typography>
          <Typography fontWeight="bold" variant="h7" component={Link} to="/help-center" className={classes.navLink}>
            Help
          </Typography>
          <Typography fontWeight="bold" variant="h7" component={Link} to="/privacy-policy" className={classes.navLink}>
            Privacy
          </Typography>
          <Typography fontWeight="bold" variant="h7" component={Link} to="/terms-and-conditions" className={classes.navLink}>
            Terms
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
