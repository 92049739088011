import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '24px',
  },
  sectionContent: {
    marginBottom: theme.spacing(2),
    color: '#333333',
    lineHeight: '1.6',
  },
}));

function TermsAndConditions() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.sectionTitle}>
        Terms and Conditions
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        Welcome to Scrapdulo, a platform provided by DULO Enterprises. By accessing and using our application or website,
        you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
      </Typography>

      {/* Add Delivery and Shipping Policy section */}
      <Typography variant="h3" className={classes.sectionTitle}>
        Delivery and Shipping Policy
      </Typography>
      <Typography variant="body1" className={classes.sectionContent}>
        At ScrapDULO, we strive to provide a seamless and efficient delivery and shipping process for our customers. Please
        review our policy below for more information:
      </Typography>

      <Typography variant="h4" className={classes.sectionTitle}>
        Delivery Options
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We offer the following delivery options for your convenience:
      </Typography>

      <ul className={classes.sectionContent}>
        <li>
          Scrap Pickup:
          <ul>
            <li>Once you submit a scrap pickup request, our registered scrap dealer will contact you to schedule the pickup.</li>
            <li>The dealer will arrive at the provided location to collect the scrap items.</li>
            <li>Make sure to have the scrap items ready for pickup and be available during the scheduled time slot.</li>
          </ul>
        </li>
        <li>
          Drop-Off:
          <ul>
            <li>Alternatively, you can choose to drop off your scrap items at the designated collection points.</li>
            <li>We have established collection points in convenient locations for easy access.</li>
            <li>Ensure that your scrap items are properly packed and labeled for drop-off.</li>
          </ul>
        </li>
      </ul>

      <Typography variant="h4" className={classes.sectionTitle}>
        Shipping Policy
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        Please note that ScrapDULO is primarily a platform for scrap pickup and does not offer shipping services for scrap items.
        Our focus is on connecting customers with registered scrap dealers for direct pickup or drop-off. Therefore, shipping
        of scrap items through postal or courier services is not available.
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        If you have any further questions or need clarification regarding our delivery and shipping policy, please contact our
        customer support team at support@scrapdulo.com. We are here to assist you.
      </Typography>

      {/* Add Privacy Policy section */}
      <Typography variant="h3" className={classes.sectionTitle}>
        Privacy Policy
      </Typography>

      

      <Typography variant="body1" className={classes.sectionContent}>
        At DULO Enterprises, we value the privacy of our users and strive to protect their personal information. This Privacy Policy outlines how we collect, use, and safeguard the information you provide when using our Scrapdulo application or website. By using our platform, you consent to the practices described in this policy.
      </Typography>

      <Typography variant="h4" className={classes.sectionTitle}>
        Information We Collect
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        When you register for an account on Scrapdulo, we collect the following types of information:
        <ul>
          <li>Your name, email address, contact information, and other details necessary for registration.</li>
          <li>Information about your scrap selling preferences, such as the types of scrap items you deal with.</li>
          <li>Transaction details, including the scrap items you sell and the dealers you engage with through our platform.</li>
          <li>Feedback and communication provided through customer support channels or feedback forms.</li>
          <li>Usage data, such as your IP address, device information, and browsing activity on Scrapdulo.</li>
        </ul>
      </Typography>

      <Typography variant="h4" className={classes.sectionTitle}>
        Sharing of Information
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We may share your personal information in the following circumstances:
        <ul>
          <li>With scrap dealers registered on Scrapdulo to facilitate the buying and selling process.</li>
          <li>With service providers who assist us in operating our platform and providing our services.</li>
          <li>If required by law, to enforce our legal rights or protect the safety of our users or others.</li>
        </ul>
      </Typography>

      <Typography variant="h4" className={classes.sectionTitle}>
        Security Measures
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We take appropriate measures to protect the security of your personal information. We implement industry-standard security technologies and procedures to prevent unauthorized access, disclosure, alteration, or destruction of data. However, please note that no method of transmission over the internet or electronic storage is 100% secure.
      </Typography>

      <Typography variant="h4" className={classes.sectionTitle}>
        Changes to the Privacy Policy
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting the revised Privacy Policy. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
      </Typography>

      {/* Add Refund and Cancellation section */}
      <Typography variant="h3" className={classes.sectionTitle}>
        Refund and Cancellation
      </Typography>

<Typography variant="body1" className={classes.sectionContent}>
  At ScrapDULO, we understand that circumstances may change, and you may need to cancel your scrap pickup request.
  We strive to provide a hassle-free experience for our customers. Therefore, we offer a cancellation option for
  scrap pickup requests, and you will not incur any fees or charges for canceling.
</Typography>

<Typography variant="body1" className={classes.sectionContent}>
  Once you have placed a scrap pickup request and decide to cancel it before the dealer arrives, you can easily do
  so through our platform. Simply navigate to your pending requests and select the cancellation option. No fees or
  penalties will be applied.
</Typography>

<Typography variant="body1" className={classes.sectionContent}>
  However, please note that once the scrap is picked up by the dealer and the dealer has paid you the cash price
  for it, there is no refund policy applicable. The transaction is considered complete, and no refunds or returns
  will be processed for the scrap items sold.
</Typography>

<Typography variant="body1" className={classes.sectionContent}>
  We strive to maintain transparency and fairness in our operations, ensuring that both customers and dealers have
  a positive experience on our platform. If you have any further questions or concerns regarding our refund and
  cancellation policy, please feel free to contact our customer support team at support@scrapdulo.com. We are here
  to assist you.
</Typography>

      {/* Add Online Registration section */}
      <Typography variant="h3" className={classes.sectionTitle}>
        Online Registration
      </Typography>
      
      <Typography variant="body1" className={classes.sectionContent}>
        At ScrapDULO, we offer convenient registration options for both customers and scrap dealers. Please see below for
        the registration process based on your role:
      </Typography>

      <Typography variant="h4" className={classes.sectionTitle}>
        Customer Registration
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        As a customer, you can easily register and submit scrap pickup requests through our website or mobile app. Here's
        how it works:
      </Typography>

      <ol className={classes.sectionContent}>
        <li>
          Website Registration:
          <ul>
            <li>Visit our website and click on "Get Started" to begin the registration process.</li>
            <li>Fill in the necessary information, such as your mobile number, email, and the quantity of scrap items.</li>
            <li>Submit the form to create your account and proceed with the scrap pickup request.</li>
          </ul>
        </li>
        <li>
          Mobile App Registration:
          <ul>
            <li>Download our Android app from the app store.</li>
            <li>Launch the app and register using your mobile number.</li>
            <li>Follow the instructions to complete the registration process.</li>
            <li>Once registered, you can submit scrap pickup requests through the app.</li>
          </ul>
        </li>
      </ol>

      <Typography variant="h4" className={classes.sectionTitle}>
        Scrap Dealer Registration
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        Scrap dealers who wish to register on ScrapDULO can do so by following these steps:
      </Typography>

      <ol className={classes.sectionContent}>
        <li>
          Send Registration Request:
          <ul>
            <li>Send a registration request to business@scrapdulo.com from your business email.</li>
            <li>Include your contact details and a brief introduction to your business in the email.</li>
          </ul>
        </li>
        <li>
          Callback from DULO Enterprises:
          <ul>
            <li>Our DULO Enterprises executive will review your registration request.</li>
            <li>If approved, you will receive a callback from our executive for further verification and onboarding.</li>
          </ul>
        </li>
        <li>
          Android App Registration:
          <ul>
            <li>If you prefer, you can also register as a dealer using our Android app.</li>
            <li>Download the app from the app store and register with your mobile number.</li>
            <li>Follow the instructions provided in the app for dealer registration.</li>
          </ul>
        </li>
      </ol>

      <Typography variant="body1" className={classes.sectionContent}>
        If you have any questions or need assistance with the registration process, please contact our support team at
        support@scrapdulo.com. We're here to help!
      </Typography>

      {/* Add Product/Service details with Pricing Structure section */}
      <Typography variant="h3" className={classes.sectionTitle}>
        Product / Service Details with Pricing Structure
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        At ScrapDULO, we provide a wide range of scrap products and services to cater to your recycling needs. Our pricing
        structure is designed to offer competitive rates for different types of scraps. Please review the details below:
      </Typography>

      <Typography variant="h4" className={classes.sectionTitle}>
        Scrap Types and Current Prices
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We offer pricing information for various types of scrap materials, including but not limited to:
      </Typography>

      <ul className={classes.sectionContent}>
        <li>Iron and Steel Scrap</li>
        <li>Aluminum Scrap</li>
        <li>Copper Scrap</li>
        <li>Brass Scrap</li>
        <li>Plastic Scrap</li>
        <li>Paper Scrap</li>
        <li>Electronic Waste</li>
        <li>And more...</li>
      </ul>

      <Typography variant="body1" className={classes.sectionContent}>
        Please note that the prices for different scrap types are subject to change and may fluctuate based on market conditions
        and other factors. We strive to provide you with the most up-to-date and competitive prices for your convenience.
      </Typography>

      <Typography variant="h4" className={classes.sectionTitle}>
        Negotiations and Custom Pricing
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We understand that negotiations and custom pricing may be necessary in certain cases. If you have a large quantity of
        scrap or require special arrangements, our registered scrap dealers are open to discussions. Please reach out to them
        through our platform or contact our customer support team for assistance.
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        For specific pricing details and information on the current prices of different scrap types, please access the ScrapDULO
        application. Our app provides real-time pricing updates and ensures transparency in the buying and selling process.
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        If you have any further questions or need clarification regarding our product/service details or pricing structure,
        please contact our customer support team at support@scrapdulo.com. We are here to assist you.
      </Typography>

      {/* Continue with the existing Terms and Conditions content */}
      <Typography variant="h3" className={classes.sectionTitle}>
        Use of Scrapdulo
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        The use of Scrapdulo is subject to the following terms and conditions:
      </Typography>

      <ol className={classes.sectionContent}>
        <li>
          The content of the pages of Scrapdulo is for your general information and use only. It is subject to change
          without notice.
        </li>
        <li>
          Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
          performance, completeness, or suitability of the information and materials found or offered on Scrapdulo for
          any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors,
          and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
        </li>
        <li>
          Your use of any information or materials on Scrapdulo is entirely at your own risk, for which we shall not be
          liable. It shall be your own responsibility to ensure that any products, services, or information available
          through Scrapdulo meet your specific requirements.
        </li>
        <li>
          Scrapdulo contains material that is owned by or licensed to us. This material includes, but is not limited to,
          the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with
          the copyright notice, which forms part of these terms and conditions.
        </li>
      </ol>

      <Typography variant="h3" className={classes.sectionTitle}>
        Limitation of Liability
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        In no event shall DULO Enterprises be liable for any direct, indirect, incidental, special, consequential, or
        exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other
        intangible losses arising out of or in connection with the use or inability to use Scrapdulo.
      </Typography>

      <Typography variant="h3" className={classes.sectionTitle}>
        Indemnity
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        You agree to indemnify and hold DULO Enterprises harmless from any claim or demand, including reasonable
        attorneys' fees, made by any third party due to or arising out of your breach of these terms and conditions or
        your violation of any law or the rights of a third party.
      </Typography>

      {/* Include additional sections and details as necessary to complete the Terms and Conditions */}
      
      <Typography variant="h3" className={classes.sectionTitle}>
        Governing Law and Jurisdiction
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        These terms and conditions are governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Delhi, India.
      </Typography>

      <Typography variant="h3" className={classes.sectionTitle}>
        Contact Us
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        If you have any questions or concerns regarding our Terms and Conditions or the practices of Scrapdulo, please
        contact us at terms@scrapdulo.com.
      </Typography>
    </div>
  );
}

export default TermsAndConditions;
