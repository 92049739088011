import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import BestRatesImage from '../assets/best_rates_image.jpg';
import ConvenienceImage from '../assets/convenience_image.jpg';
import TrustImage from '../assets/trust_image.jpg';
import EcoFriendlyImage from '../assets/eco_friendly_image.jpg';

const useStyles = makeStyles((theme) => ({
    section: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(4),
      color: '#ffffff',
    },
    featureCard: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1), // Add  padding
      boxShadow: 'none',
      backgroundColor: '#ffffff',
      height: '90%',
      width:'100%'
    },
    featureCardContent: {
      flexGrow: 1,
    },
    image: {
      objectFit: 'contain',
      height: '100px',
    },
    featureTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(1),
    },
    featureDescription: {
      textAlign: 'center'
    },
  }));
  
  const WhyUsSection = () => {
    const classes = useStyles();
    const theme = useTheme();
  
    return (
      <section className={classes.section}>
        <Typography variant="h4" component="h4" align="center" className={classes.title}>
          Why Us
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.featureCard}>
              <CardMedia
                component="img"
                image={BestRatesImage}
                style={{"objectFit":"contain"}}
                alt="Best Rates"
                className={classes.image}
              />
              <CardContent className={classes.featureCardContent}>
                <Typography variant="h7" component="h3" className={classes.featureTitle}>
                  Best Rates
                </Typography>
                <Typography variant="body2" className={classes.featureDescription}>
                  We provide the best value for your scrap from our network of Recyclers.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.featureCard}>
              <CardMedia
                component="img"
                image={ConvenienceImage}
                style={{"objectFit":"contain"}}
                alt="Convenience"
                className={classes.image}
              />
              <CardContent className={classes.featureCardContent}>
                <Typography variant="h7" component="h3" className={classes.featureTitle}>
                  Convenience
                </Typography>
                <Typography variant="body2"  className={classes.featureDescription}>
                  Doorstep pickup according to user's convenient date & time.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.featureCard}>
              <CardMedia component="img"
                style={{"objectFit":"contain"}}
                 image={TrustImage} alt="Trust"
                  className={classes.image} />
              <CardContent className={classes.featureCardContent}>
                <Typography variant="h7" component="h3" className={classes.featureTitle}>
                  Trust
                </Typography>
                <Typography variant="body2" className={classes.featureDescription}>
                  Trained & Verified Pickup Staff with ScrapDULO Smart Weighing Scale
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.featureCard}>
              <CardMedia
                component="img"
                image={EcoFriendlyImage}
                style={{"objectFit":"contain"}}
                alt="Eco-friendly"
                className={classes.image}
              />
              <CardContent className={classes.featureCardContent}>
                <Typography variant="h7" component="h3" className={classes.featureTitle}>
                  Eco-friendly
                </Typography>
                <Typography variant="body2" className={classes.featureDescription}>
                  We ensure responsible recycling of your scrap items.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </section>
    );
  };
  

export default WhyUsSection;
