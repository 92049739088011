import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  email: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  phone: {
    fontWeight: 'bold',
  },
}));

const ContactPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Contact Us
      </Typography>
      <Typography variant="body1" className={classes.content}>
        We value your feedback and are here to assist you. If you have any questions, inquiries, or suggestions, please feel free to reach out to us. We strive to provide the best possible support to our users.
      </Typography>
      <div className={classes.contactInfo}>
        <Typography variant="body1" className={classes.content}>
          For general inquiries or support, you can contact our support team at:
        </Typography>
        <Typography variant="body1" className={classes.email}>
          support@scrapdulo.com
        </Typography>
        <Typography variant="body1" className={classes.content}>
          For business inquiries, please email us at:
        </Typography>
        <Typography variant="body1" className={classes.email}>
          duloenterprisesbusiness@gmail.com
        </Typography>
        <Typography variant="body1" className={classes.content}>
          If you prefer to speak with us directly, you can reach us at:
        </Typography>
        <Typography variant="body1" className={classes.phone}>
          +91-844-828-0993
        </Typography>
      </div>
      <Typography variant="body1" className={classes.content}>
        Our team is available during business hours to assist you with any inquiries or concerns you may have. We strive to respond to all inquiries promptly and provide the necessary support.
      </Typography>
      <Typography variant="body1">
          Company Address:
        </Typography>
        <Typography variant="body1">
          H No. C70, Gali No 15, Madanpur Khadar Extension
        </Typography>
        <Typography variant="body1">
          Sarita Vihar
        </Typography>
        <Typography variant="body1">
          New Delhi
        </Typography>
        <Typography variant="body1">
          Pin: 110076
        </Typography>
      <Typography variant="body1" className={classes.content} hidden>
        We also encourage you to connect with us on our social media platforms to stay updated with the latest news, announcements, and promotions:
      </Typography>
      <ul className={classes.content} hidden>
        <li>Facebook: www.facebook.com/scrapdulo</li>
        <li>Twitter: www.twitter.com/scrapdulo</li>
        <li>Instagram: www.instagram.com/scrapdulo</li>
        <li>LinkedIn: www.linkedin.com/company/scrapdulo</li>
      </ul>
      <Typography variant="body1" className={classes.content}>
        We appreciate your interest in Scrapdulo and look forward to hearing from you. Your feedback helps us improve our services and create a better experience for all our users.
      </Typography>
    </div>
  );
};

export default ContactPage;
