import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '24px',
  },
  sectionContent: {
    marginBottom: theme.spacing(2),
    color: '#333333',
    lineHeight: '1.6',
  },
}));

function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.sectionTitle}>
        Privacy Policy
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        At DULO Enterprises, we value the privacy of our users and strive to protect their personal information.
        This Privacy Policy outlines how we collect, use, and safeguard the information you provide when using our
        Scrapdulo application or website. By using our platform, you consent to the practices described in this
        policy.
      </Typography>

      <Typography variant="h3" className={classes.sectionTitle}>
        Information We Collect
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        When you register for an account on Scrapdulo, we collect the following types of information:
      </Typography>

      <ul className={classes.sectionContent}>
        <li>Your name, email address, contact information, and other details necessary for registration.</li>
        <li>Information about your scrap selling preferences, such as the types of scrap items you deal with.</li>
        <li>Transaction details, including the scrap items you sell and the dealers you engage with through our platform.</li>
        <li>Feedback and communication provided through customer support channels or feedback forms.</li>
        <li>Usage data, such as your IP address, device information, and browsing activity on Scrapdulo.</li>
      </ul>

      {/* Continue with the Privacy Policy content, adding more sections and details */}
      
      <Typography variant="h3" className={classes.sectionTitle}>
        Sharing of Information
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We may share your personal information in the following circumstances:
      </Typography>

      <ul className={classes.sectionContent}>
        <li>With scrap dealers registered on Scrapdulo to facilitate the buying and selling process.</li>
        <li>With service providers who assist us in operating our platform and providing our services.</li>
        <li>If required by law, to enforce our legal rights or protect the safety of our users or others.</li>
      </ul>

      {/* Include additional sections and details as necessary to complete the Privacy Policy */}
      
      <Typography variant="h3" className={classes.sectionTitle}>
        Security Measures
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We take appropriate measures to protect the security of your personal information. We implement industry-standard
        security technologies and procedures to prevent unauthorized access, disclosure, alteration, or destruction of data.
        However, please note that no method of transmission over the internet or electronic storage is 100% secure.
      </Typography>

      <Typography variant="h3" className={classes.sectionTitle}>
        Changes to the Privacy Policy
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will
        be effective immediately upon posting the revised Privacy Policy. We encourage you to review this Privacy Policy
        periodically to stay informed about how we are protecting your information.
      </Typography>

      <Typography variant="h3" className={classes.sectionTitle}>
        Contact Us
      </Typography>

      <Typography variant="body1" className={classes.sectionContent}>
        If you have any questions or concerns regarding our Privacy Policy or the practices of Scrapdulo, please contact
        us at support@scrapdulo.com.
      </Typography>
    </div>
  );
}

export default PrivacyPolicy;
